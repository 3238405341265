
import { defineComponent, reactive, ref } from "vue";
import xlsx from "xlsx";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { addArdomusDevices } from "@/core/services/api/device";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  components: {
    Datatable,
    Dialog,
  },
  setup() {
    const xlsxData: any = reactive([]);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "描述",
        key: "description",
        sortable: true,
      },
      {
        name: "類型",
        key: "type",
        sortable: true,
      },
      {
        name: "虛擬類型",
        key: "virtualType",
        sortable: true,
      },
      {
        name: "Modbus Slave Ip",
        key: "ip",
        sortable: true,
      },
      {
        name: "Modbus Slave port",
        key: "port",
        sortable: true,
      },
      {
        name: "Modbus Slave Id",
        key: "slaveId",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const pointTableData: any = reactive([]);
    const pointTableHeader = ref([
      {
        name: "點位類型",
        key: "type",
        sortable: true,
      },
      {
        name: "起始IP位置",
        key: "startAddress",
        sortable: true,
      },
      {
        name: "Modbus FunctionCode",
        key: "functionCode",
        sortable: true,
      },
      {
        name: "單位名稱",
        key: "unit",
        sortable: true,
      },
      {
        name: "長度",
        key: "length",
        sortable: true,
      },
      {
        name: "縮放尺度",
        key: "scale",
        sortable: true,
      },
      {
        name: "Code",
        key: "code",
        sortable: true,
      },
    ]);
    const descriptionObj: any = reactive({});
    const virtualTypeObj: any = reactive({});
    const pointDataVisible = ref(false);

    const readFile = (file) => {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (ev: any) => {
          resolve(ev.target.result);
        };
      });
    };

    const uploadChange = async (file) => {
      console.log('uploadChange')
      let dataBinary = await readFile(file.raw);
      let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = xlsx.utils.sheet_to_json(workSheet);
      xlsxData.splice(0, xlsxData.length, ...data);
      console.log(xlsxData);
     
      setTableData();
    };

    // 轉換functionCode
    const processFunctionCode = (code) => {
      switch (code) {
        case "1":
          return "ReadCoils";
        case "2":
          return "ReadDiscreteInputs";
        case "3":
          return "ReadHoldingRegisters";
        case "4":
          return "ReadInputRegisters";
        case "5":
          return "WriteSingleCoil";
        case "6":
          return "WriteSingleHoldingRegister";
        case "15":
          return "WriteMultipleCoils";
        case "16":
          return "WriteMultipleHoldingRegisters";
      }
    };

    const setTableData = () => {
      // 清空目前的table資料
      tableData.splice(0);
      for (const item of xlsxData) {
        const existDeviceIndex = tableData.findIndex(
          (o) => o.name === item["DeviceName"]
        );
        const registerFunctionCodes: any =
          item["Register Funtion Code"].split("/");
        const functionCodes: any = [];
        registerFunctionCodes.forEach((o) => {
          functionCodes.push(processFunctionCode(o));
        });

        if (existDeviceIndex == -1) {
          tableData.push({
            name: item["DeviceName"],
            description: item["DeviceName"],
            type: item["DeviceType"],
            virtualType: item["DeviceType"],
            ip: item["Modbus Slave Ip"],
            port: item["Modbus Slave Port"],
            slaveId:item["Slave Id"],
            pointDataList: [
              {
                type: item["DataPointType"],
                startAddress: item["Register Address"],
                functionCode: functionCodes.join(","),
                unit:
                  item["Register Unit"] == undefined
                    ? ""
                    : item["Register Unit"],
                length: item["Register Length"],
                scale: item["Register Scale"],
                code: item["Code"],
              },
            ],
          });
        } else {
          let existItem = tableData.filter(
            (o) => o.name == item["DeviceName"]
          )[0];
          existItem.pointDataList.push({
            type: item["DataPointType"],
            startAddress: item["Register Address"],
            functionCode: functionCodes.join(","),
            unit:
              item["Register Unit"] == undefined ? "" : item["Register Unit"],
            length: item["Register Length"],
            scale: item["Register Scale"],
            code: item["Code"] == undefined ? "" : item["Code"],
          });
        }
        console.log("tableData", tableData);
        descriptionObj[item["DeviceName"]] = item["DeviceName"];
        virtualTypeObj[item["DeviceName"]] = item["DeviceType"];
      }
    };

    const showPointData = (item) => {
      pointTableData.splice(0, pointTableData.length, ...item.pointDataList);
      pointDataVisible.value = true;
    };

    const handleAddArdomusDevices = async () => {
      await Swal.fire({
        title: "您確定要匯入嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request: any = [];
          for (const item of tableData) {
            request.push({
              ardomusDataPointsBasicData: item.pointDataList,
              ip: item.ip,
              port: item.port,
              slaveId:item.slaveId,
              vendor: "Ardomus",
              type: item.type,
              virtualType:
                virtualTypeObj[item.name] == item.virtualType
                  ? item.virtualType
                  : virtualTypeObj[item.name],
              name: item.name,
              description: descriptionObj[item.name],
            });
          }
          console.log('request:', request);
          await addArdomusDevices(currentSpaceGuid!,request).then( async() => {
            await Swal.fire("匯入成功!", "", "success");
          })
        }
      });
    };

    return {
      uploadChange,
      tableData,
      tableHeader,
      pointTableData,
      pointTableHeader,
      descriptionObj,
      virtualTypeObj,
      pointDataVisible,
      showPointData,
      handleAddArdomusDevices,
    };
  },
});
